import React from 'react';
import { connect } from 'react-redux';
import ReportsTable from './ReportsTable';
import { openNotificationDetailReport } from '../../../actions/reportTrackingAction';
import styles from '../../../styles/user/dashboard/MyTasks.module.scss';

const ReportsWidget = props => {
  let reports;
  let title;
  let num;
  if (props.reportTypes === 'past_due') {
    reports = props.dueReportsNotification;
    num = props.dueReportsNotification.count;
    title = `Late ${
      props.dueReportsNotification.count === 1 ? 'Op Req' : 'Op Reqs'
    }`;
  } else if (props.reportTypes === 'upcoming') {
    reports = props.upcomingReportsNotification;
    num = props.upcomingReportsNotification.count;
    title = `Upcoming ${
      props.upcomingReportsNotification.count === 1 ? 'Op Req' : 'Op Reqs'
    }`;
  } else if (props.reportTypes === 'recent') {
    reports = props.recentReportsNotification;
    num = props.recentReportsNotification.count;
    title = (
      <>
        Recently Uploaded
        <small>
          Total: {props.recentReportsNotification.count}
          {props.recentReportsNotification.count === 1 ? 'Report' : 'Reports'}
        </small>
      </>
    );
  }

  return (
    <>
      {props.reportTypes !== 'recent' && (
        <div
          className={`card shadow-sm flex-fill border-0 ${
            props.reportTypes === 'upcoming' ? 'bg-warning' : 'bg-danger'
          }  mb-3`}>
          <div className='card-body p-3'>
            <div className='d-flex align-items-start'>
              <div className='flex-grow-1'>
                <h1 className='card-title text-white text-center fw-bold mb-0'>
                  {num}
                </h1>
              </div>
              <div className='d-inline-block ms-3'>
                <p className='card-text text-white lh-1 mb-1'>{title}</p>
                <button
                  className='btn p-0 btn-sm text-white'
                  onClick={() =>
                    (window.location.href =
                      props.reportTypes === 'upcoming'
                        ? `/myTasks/myOpReqs?filter=upcoming`
                        : `/myTasks/myOpReqs?filter=past`)
                  }>
                  View All
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
      {props.reportTypes === 'recent' && (
        <div className={`mb-3 ${styles['widget-container']}`}>
          <ReportsTable
            allReports={reports}
            tableType={props.reportTypes}
            loading={props.isLoading}
          />
        </div>
      )}
    </>
  );
};

const mapStateToProps = state => {
  return {
    dueReportsNotification: state.userReducer.dueReportsNotification,
    isLoading: state.reportTrackingReducer.isLoading,
    recentReportsNotification: state.userReducer.recentReportsNotification,
    upcomingReportsNotification: state.userReducer.upcomingReportsNotification,
  };
};

const mapDispatchToProps = {
  openNotificationDetailReport,
};

export default connect(mapStateToProps, mapDispatchToProps)(ReportsWidget);
