import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import EditUserForm from '../EditUserForm';
import styles from '../../../styles/user/UserProfile.module.scss';
import {
  EditPageIcon,
  UserProfileCircleIcon,
} from '../../../services/SvgLibrary';
import { Link } from 'react-router-dom';
import { rolesUnderCouncilMembers } from '../../../services/constants';
import { useNavigate, useParams } from 'react-router-dom'; // Import these hooks
import { propTypes } from 'react-sidebar';

const CentralStaff = ({
  users,
  isSuperUser,
  userProfile,
  committeeList,
  newUserCreationStatus,
}) => {
  const navigate = useNavigate(); // Use the useHistory hook
  const { id } = useParams(); // Use the useParams hook]

  const [state, setState] = useState({
    fullStaffList: [],
    filteredStaffList: [],
    filteredNonStaffList: [],
    selectedStaff: null,
    currentSearchType: false,
    committeeSearch: '',
    inputSearch: '',
    superuser: null,
    userRole: '',
    userProfile: '',
  });

  useEffect(() => {
    if (id) {
      const filterStaff = users.filter(c => c.id === parseInt(id));
      if (filterStaff.length === 1) {
        setState(prevState => ({
          ...prevState,
          selectedStaff: filterStaff[0],
        }));
      } else {
        console.log(
          `There are ${filterStaff.length} matches for this username!`
        );
      }
    }

    console.log('ID', id);

    setState(prevState => ({
      ...prevState,
      fullStaffList: users,
      filteredStaffList: prevState.filteredStaffList.length
        ? prevState.filteredStaffList
        : users,
      superuser: isSuperUser,
      userRole: userProfile.role,
      userProfile,
    }));
  }, [users, isSuperUser, userProfile, id]);

  const handleUrlChange = id => {
    navigate(`/staff/${id}`);
  };

  useEffect(() => {
    if (state.selectedStaff) {
      handleUrlChange(state.selectedStaff.id);
    }
  }, [state.selectedStaff]);

  const toggleSearchType = () => {
    setState(prevState => ({
      ...prevState,
      currentSearchType: !state.currentSearchType,
      filteredStaffList: users,
      inputSearch: '',
      committeeSearch: '',
    }));
  };

  const onSearchByInput = e => {
    const input = e.target.value;
    setState(prevState => ({
      ...prevState,
      inputSearch: input,
    }));
    const filterStaff = users.filter(c =>
      c.full_name
        .toLowerCase()
        .normalize('NFD')
        .replace(/[\u0300-\u036f]/g, '')
        .includes(
          input
            .toLowerCase()
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, '')
        )
    );
    setState(prevState => ({
      ...prevState,
      filteredStaffList: filterStaff,
    }));
  };

  const onSearchByCommittee = e => {
    let committee = e.target.value;
    setState(prevState => ({ ...prevState, committeeSearch: committee }));
    const filteredStaff = users.filter(c => {
      let titles = c.committees.map(com => com.committee_name);
      return (
        titles.includes(committee) && !rolesUnderCouncilMembers.includes(c.role)
      );
    });
    setState(prevState => ({ ...prevState, filteredStaffList: filteredStaff }));

    const filteredNonStaff = users.filter(c => {
      let titles = c.committees.map(com => com.committee_name);
      return (
        titles.includes(committee) && rolesUnderCouncilMembers.includes(c.role)
      );
    });
    setState(prevState => ({
      ...prevState,
      filteredNonStaffList: filteredNonStaff,
    }));
  };

  const {
    fullStaffList,
    filteredStaffList,
    filteredNonStaffList,
    committeeSearch,
  } = state;

  let under_council_member, associated_cm;

  if (state.selectedStaff) {
    under_council_member = state.selectedStaff.under_council_member;

    associated_cm = fullStaffList.find(u => u.id === under_council_member);
  } else {
    under_council_member = null;
    associated_cm = null;
  }

  const staffDisplay = filteredStaffList.map((user, idx) => {
    return (
      <tr key={user.id} className={styles['user-row']}>
        <td className={styles['user-view']}>
          <a
            style={{ color: '#FFF' }}
            href={'mailto:' + user.email}
            title={'Email ' + user.full_name}>
            {user.profile_pic_icon ? (
              <img alt='My Profile Icon' src={user.profile_pic_icon.file} />
            ) : (
              <UserProfileCircleIcon />
            )}
          </a>
        </td>
        <td className={styles['user-name-email']}>
          <button
            className='pe-3 d-flex align-items-center justify-content-between'
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                selectedStaff: filteredStaffList[idx],
              }));
            }}>
            {user.full_name}
            <span style={{ fontSize: '.6em' }}>
              <em>
                {userProfile.permissions.auth.change_user &&
                  !user.is_active &&
                  '(Deactivated)'}
              </em>
            </span>
          </button>
        </td>
      </tr>
    );
  });

  const nonStaffDisplay = filteredNonStaffList?.map((user, idx) => {
    return (
      <tr key={user.id} className={styles['user-row']}>
        <td className={styles['user-view']}>
          <a
            style={{ color: '#FFF' }}
            href={'mailto:' + user.email}
            title={'Email ' + user.full_name}>
            {user.profile_pic_icon ? (
              <img alt='My Profile Icon' src={user.profile_pic_icon.file} />
            ) : (
              <UserProfileCircleIcon />
            )}
          </a>
        </td>
        <td className={styles['user-name-email']}>
          <button
            onClick={() => {
              setState(prevState => ({
                ...prevState,
                selectedStaff: filteredNonStaffList[idx],
              }));
            }}>
            {user.full_name}
            <span style={{ fontSize: '.6em' }}>
              <em>
                {userProfile.permissions.auth.change_user &&
                  !user.is_active &&
                  '(Deactivated)'}
              </em>
            </span>
          </button>
        </td>
      </tr>
    );
  });

  return (
    <div className={styles['user-profile-container']}>
      <div className={styles['user-profile-main']}>
        <div className='p-3 position-sticky top-0'>
          <div
            className={`ps-1 pe-2 py-1 w-100 d-flex align-items-center justify-content-between ${styles['user-search-wrapper']}`}>
            {!state.currentSearchType && (
              <input
                type='text'
                className={`form-control form-control-sm ${styles['user-search-input']}`}
                id='searchCouncilStaff'
                placeholder='Search Council Staff'
                value={state.inputSearch}
                onChange={onSearchByInput}
              />
            )}
            {state.currentSearchType && (
              <select
                className={`form-select ${styles['user-search-input']} ${styles['select']}`}
                id='searchCommitteeFilter'
                value={committeeSearch}
                placeholder='Select a Committee'
                onChange={onSearchByCommittee}>
                {
                  <option key={'null'} value={''} hidden>
                    Select a Committee
                  </option>
                }
                {committeeList.map((committee, idx1) => {
                  return <option key={idx1}>{committee.committee_name}</option>;
                })}
              </select>
            )}
            <div
              className={`btn-group btn-group-sm ${styles['directory-toggle-btn']}`}
              role='group'
              aria-label='Search toggle between Saff and Committee'>
              <input
                type='radio'
                className='btn-check'
                name='btnradio'
                id='btnstaff'
                autoComplete='off'
                checked={!state.currentSearchType}
                onChange={toggleSearchType}
              />
              <label className='btn btn-outline-primary' htmlFor='btnstaff'>
                Staff
              </label>

              <input
                type='radio'
                className='btn-check'
                name='btnradio'
                id='btncommittee'
                autoComplete='off'
                checked={state.currentSearchType}
                onChange={toggleSearchType}
              />
              <label className='btn btn-outline-primary' htmlFor='btncommittee'>
                Committee
              </label>
            </div>
          </div>
        </div>
        <table className={`col-12 ${styles['user-table']}`}>
          {committeeSearch.length > 0 && (
            <thead>
              <tr className={styles['user-row']}>
                <td className={styles['user-view']} colSpan='2'>
                  <p className={styles['user-table-header']}>Central Staff</p>
                </td>
              </tr>
            </thead>
          )}
          <tbody>{staffDisplay}</tbody>
        </table>
        {committeeSearch.length > 0 && (
          <hr style={{ backgroundColor: 'white' }} />
        )}
        <table className={`col-12 ${styles['user-table']}`}>
          {committeeSearch?.length > 0 && (
            <thead>
              <tr className={styles['user-row']}>
                <td className={styles['user-view']} colSpan='2'>
                  <p className={styles['user-table-header']}>
                    District Offices
                  </p>
                </td>
              </tr>
            </thead>
          )}
          <tbody>{nonStaffDisplay}</tbody>
        </table>
      </div>
      <div className={styles['user-profile-info-panel']}>
        {!state.selectedStaff && (
          <div className={styles['image-and-text-placeholder']}>
            <img src='/img/central-staff-icon.png' alt='User profile icon' />
            <h1>Staff Directory</h1>
          </div>
        )}
        {state.selectedStaff && (
          <div>
            <div
              className='card'
              style={{ border: '0px', marginBottom: '25px' }}>
              <img
                src='/img/my-profile-cover-image.jpg'
                className={`card-img-top ${styles['staff-directory-cover-image']}`}
                aria-hidden='true'
                alt='Placeholder user profile banner'
              />
              <h1 className={styles['users-name']}>
                {state.selectedStaff.full_name}
              </h1>
              <img
                src={`${
                  state.selectedStaff.profile_pic_icon
                    ? state.selectedStaff.profile_pic_icon.file
                    : '/img/profile-image-placeholder.png'
                }`}
                className={styles['staff-directory-profile']}
                alt='User profile icon'
              />
              {userProfile.permissions.auth.change_user ? (
                <button
                  type='button'
                  className={styles['edit-profile-button']}
                  data-bs-toggle='modal'
                  data-bs-target='#editCMUserProfile'>
                  <EditPageIcon /> Edit Profile
                </button>
              ) : null}
              <div className='card-body'>
                <table className={styles['staff-directory-details']}>
                  <tbody>
                    {userProfile.permissions.auth.change_user &&
                      !state.selectedStaff.is_active && (
                        <tr>
                          <td colSpan='2'>
                            <strong>DEACTIVATED</strong>
                          </td>
                        </tr>
                      )}
                    <tr>
                      <td>Username</td>
                      <td>{state.selectedStaff.username}</td>
                    </tr>
                    <tr>
                      <td>Role</td>
                      <td>{state.selectedStaff.role}</td>
                    </tr>
                    <tr>
                      <td>Email</td>
                      <td>
                        <a
                          href={`mailto:${state.selectedStaff.email}`}
                          style={{ color: '#4e46d2' }}>
                          {state.selectedStaff.email}
                        </a>
                      </td>
                    </tr>
                    <tr>
                      <td>Phone</td>
                      <td>{state.selectedStaff.phone}</td>
                    </tr>
                    <tr>
                      <td>Alternative Phone</td>
                      <td>{state.selectedStaff.alternative_phone || 'N/A'}</td>
                    </tr>
                    {state.selectedStaff.desk_number &&
                      userProfile.desk_number && (
                        <tr>
                          <td>Desk Number</td>
                          <td>
                            {userProfile.bio && userProfile.profile_pic ? (
                              <Link
                                to='/seating'
                                state={{
                                  selectedStaff: state.selectedStaff,
                                }}>
                                {state.selectedStaff.desk_number}
                              </Link>
                            ) : (
                              <a
                                href='#'
                                onClick={e => {
                                  e.preventDefault();
                                  alert(
                                    'ERROR! \n401 UNAUTHORIZED! \nUpdate your bio AND profile picture to see the floor plan!'
                                  );
                                }}>
                                {state.selectedStaff.desk_number}
                              </a>
                            )}
                          </td>
                        </tr>
                      )}
                  </tbody>
                </table>
              </div>
            </div>
            {state.selectedStaff.bio && (
              <div className='card mb-4 border-0 rounded-3'>
                <div className='card-body'>
                  <h2>Bio</h2>
                  <p>{state.selectedStaff.bio}</p>
                </div>
              </div>
            )}
            <div className={`card ${styles['staff-associations']}`}>
              <div className='card-body'>
                <table>
                  <tbody>
                    {under_council_member && associated_cm && (
                      <tr>
                        <td className={styles['association-label-top']}>
                          Associated CM:
                        </td>
                        <td>
                          <ul style={{listStyle: "none"}}>
                            <li>
                              <a href={`/staff/${associated_cm.id}`}>
                                {associated_cm.full_name}
                              </a>
                            </li>
                          </ul>
                        </td>
                      </tr>
                    )}
                    {users.filter(staff => staff.under_council_member === state.selectedStaff.id).length > 0 &&
                      (state.selectedStaff.role.includes('Council Member') || state.selectedStaff.role.includes('Public Advocate')) && (
                      <tr>
                        <td className={styles['association-label-top']}>
                          Staffers:
                        </td>
                        <td>
                          <ul>
                            {!userProfile.permissions.auth.change_user &&
                              fullStaffList
                                .filter(
                                  staff =>
                                    staff.under_council_member ===
                                      state.selectedStaff.id &&
                                    staff.is_active
                                )
                                .map((staffer, idx) => (
                                  <li key={idx}>
                                    <a href={`/staff/${staffer.id}`}>
                                      {staffer.full_name}
                                    </a>
                                  </li>
                                ))}
                            {userProfile.permissions.auth.change_user &&
                              fullStaffList
                                .filter(
                                  staff =>
                                    staff.under_council_member ===
                                    state.selectedStaff.id
                                )
                                .map((staffer, idx) => (
                                  <li key={idx}>
                                    <a
                                      href={`/staff/${staffer.id}`}
                                      style={{
                                        textDecoration: !staffer.is_active
                                          ? 'line-through'
                                          : '',
                                      }}>
                                      {staffer.full_name}
                                    </a>
                                    {!staffer.is_active && (
                                      <strong>&#91;DEACTIVATED&#93;</strong>
                                    )}
                                  </li>
                                ))}
                          </ul>
                        </td>
                      </tr>
                    )}

                    {state.selectedStaff.division && (
                      <tr>
                        <td className={styles['association-label-top']}>
                          Division/Teams:
                        </td>
                        <td>
                          <ul>
                            {state.selectedStaff.division.map((div, idx) => (
                              <li key={idx}>{div.division_name}</li>
                            ))}
                          </ul>
                        </td>
                      </tr>
                    )}

                    {state.selectedStaff.committees?.length > 0 && (
                      <tr>
                        <td className={styles['association-label-top']}>
                          Committees:
                        </td>
                        <td>
                          <ul>
                            {state.selectedStaff.committees &&
                              state.selectedStaff.committees.map(
                                (committee, idx) => (
                                  <li key={idx}>
                                    {committee.committee_name}
                                  </li>
                                )
                              )}
                          </ul>
                        </td>
                      </tr>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            <div
              className='modal fade'
              id='editCMUserProfile'
              data-bs-backdrop='static'
              data-bs-keyboard='false'
              tabIndex='-1'
              aria-labelledby='editUserProfile'
              aria-hidden='true'>
              <div className='modal-dialog modal-lg'>
                <div className='modal-content'>
                  <div className='modal-header'>
                    <h5 className='modal-title' id='editUserProfile'>
                      Edit profile
                    </h5>
                    {/* {newUserCreationStatus !== 'success' && (
                      <button
                        type='button'
                        className='btn-close'
                        data-bs-dismiss='modal'
                        aria-label='Close'
                        onClick={toggleEditUserModal}></button>
                    )} */}
                  </div>
                  {state.selectedStaff && (
                    <div className='modal-body' style={{ padding: '0px' }}>
                      <EditUserForm id={state.selectedStaff.id} />
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    users: state.userReducer.userProfile.permissions.auth.change_user
      ? state.userReducer.allUserList
      : state.userReducer.userList,
    isSuperUser: state.userReducer.isSuperUser,
    userProfile: state.userReducer.userProfile,
    committeeList: state.userReducer.committees,
    newUserCreationStatus: state.userReducer.newUserCreationStatus,
  };
};

export default connect(mapStateToProps)(CentralStaff);