import React, { useState, useEffect, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { connect } from 'react-redux';
import version from '../../package.json';
import styles from '../styles/App.module.scss';
import {
  CitationRequestIcon,
  ClexIcon,
  DashboardIcon,
  DataTeamExplorerIcon,
  FloorPlanIcon,
  LsRequestIcon,
  MyTasksIcon,
  OutboundLinkIcon,
  ProclamationRequestIcon,
  ReportTrackingIcon,
  StaffDirectoryIcon,
  PlayVideoTutorialsIcon,
} from '../services/SvgLibrary';
import {
  attorneyRoles,
  legDivisionRoles,
  rolesUnderCouncilMembers,
  superAdminRoles,
} from '../services/constants';
import congratsHat from '../animations/congrats-hat.json';

function Sidebar(props) {
  const { isOpen } = props;
  const today = new Date();
  const iwommAnniversary = today.getMonth() === 4 && today.getDate() === 9;
  let logoComponent;
  // const [showShadow, setShowShadow] = useState(false);
  const navListRef = useRef(null);
  const [showTopShadow, setShowTopShadow] = useState(false);
  const [showBottomShadow, setShowBottomShadow] = useState(false);

  useEffect(() => {
    const navElement = navListRef.current;

    const checkOverflow = () => {
      const isOverflowing = navElement.scrollHeight > navElement.clientHeight;
      setShowBottomShadow(isOverflowing);
      setShowTopShadow(false);
    };

    const handleScroll = () => {
      if (!navElement) return;

      const scrollTop = navElement.scrollTop;
      const clientHeight = navElement.clientHeight;
      const scrollHeight = navElement.scrollHeight;

      // Set shadows based on scroll position
      const atTop = scrollTop === 0;
      const atBottom = scrollTop + clientHeight >= scrollHeight;

      setShowTopShadow(!atTop);
      setShowBottomShadow(!atBottom);
    };

    checkOverflow();
    navElement.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', checkOverflow);

    return () => {
      navElement.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', checkOverflow);
    };
  }, []);

  const location = useLocation();

  const [showCloseButton, setShowCloseButton] = useState(false);

  const toggleCloseButton = () => {
    setShowCloseButton(prevState => !prevState);
  };

  if (iwommAnniversary) {
    logoComponent = (
      <div className={`${styles['logo-container']} ${styles['anniversary']}`}>
        <lottie-player
          id={styles['birthday-hat']}
          src={JSON.stringify(congratsHat)}
          background='transparent'
          speed='.8'
          autoplay></lottie-player>
        <a href='/'>
          <img
            src='/img/Logo/iwomm white logo.png'
            alt='IWOMM logo'
            height='32'
            className='d-inline-block align-text-top'
          />
        </a>
        <lottie-player
          id={styles['confetti']}
          src='https://assets6.lottiefiles.com/packages/lf20_rovf9gzu.json'
          background='transparent'
          speed='.6'
          autoplay></lottie-player>
        <span className={styles['version-num']}>v {version.version}</span>
      </div>
    );
  } else {
    logoComponent = (
      <div
        className={`d-flex align-items-center flex-column justify-content-center py-4 ${styles['logo-container']}`}>
        <a href='/'>
          <img
            src='/img/Logo/iwomm white logo.png'
            alt='IWOMM logo'
            height='32'
            className='d-inline-block align-text-top'
          />
        </a>
        <span className={styles['version-num']}>v {version.version}</span>
      </div>
    );
  }
  const seatingHandler = () => {
    if (props.userProfile.bio && props.userProfile.profile_pic) {
      window.location.href = '/seating';
    } else {
      alert(
        'ERROR! \n401 UNAUTHORIZED! \nUpdate your bio AND profile picture to see the floor plan!'
      );
    }
  };
  return (
    <nav
      id={styles['sidebar']}
      style={{ marginLeft: isOpen ? '0px' : '-230px' }}>
      {logoComponent}
      <ul
        id={styles['main-nav']}
        ref={navListRef}
        className={`h-auto p-0 w-100 flex-shrink-1 mb-auto ${
          styles['sidebar-nav-list']
        } ${showTopShadow ? styles['has-top-shadow'] : ''} ${
          showBottomShadow ? styles['has-bottom-shadow'] : ''
        }`}>
        <li
          className={`${styles['sidebar-nav-item']} ${
            styles[window.location.pathname === '/' ? 'active' : '']
          }`}
          onClick={() => (window.location.href = '/')}>
          <div className={styles['sidebar-nav-icon']}>
            <DashboardIcon />
          </div>
          <div className={styles['sidebar-nav-title']}>Dashboard</div>
        </li>
        <li>
          <div className='accordion accordion-flush' id='myTasksDropdown'>
            <div className='accordion-item'>
              <div
                className={`accordion-header ${styles['sidebar-nav-list']}`}
                style={{ backgroundColor: '#293042' }}
                id='flush-headingOne'>
                <button
                  className={`accordion-button collapsed ${styles['sidebar-nav-item']}`}
                  type='button'
                  data-bs-toggle='collapse'
                  data-bs-target='#flush-collapseOne'
                  aria-expanded='false'
                  aria-controls='flush-collapseOne'>
                  <div className={styles['sidebar-nav-icon']}>
                    <MyTasksIcon />
                  </div>
                  <div
                    className={`${styles['sidebar-nav-title']} ${styles['my-tasks']}`}>
                    My Tasks
                  </div>
                </button>
              </div>
              <div
                id='flush-collapseOne'
                className={`accordion-collapse collapse ${
                  window.location.pathname.includes('/myTasks') ? 'show' : ''
                }`}
                style={{ borderRadius: '0px' }}
                aria-labelledby='flush-headingOne'
                data-bs-parent='#myTasksDropdown'>
                <div
                  className={`accordion-body ${styles['accordion-body-container-sidebar']}`}>
                  <ul className={`${styles['sidebar-nav-list']}`}>
                    {props.userProfile.permissions.lsRequest_api
                      .view_lsrequest && (
                      <>
                        <li
                          className={`${styles['sidebar-nav-item']} ${
                            styles['sidebar-subnav-item']
                          } ${
                            styles[
                              window.location.pathname.includes(
                                '/myTasks/myActiveLs'
                              )
                                ? 'active'
                                : ''
                            ]
                          }`}
                          onClick={() =>
                            (window.location.href = '/myTasks/myActiveLs')
                          }>
                          <div className={styles['sidebar-nav-title']}>
                            My Active LS Requests
                          </div>
                        </li>
                        <li
                          className={`${styles['sidebar-nav-item']} ${
                            styles['sidebar-subnav-item']
                          } ${
                            styles[
                              window.location.pathname.includes(
                                '/myTasks/allMyLs'
                              )
                                ? 'active'
                                : ''
                            ]
                          }`}
                          onClick={() =>
                            (window.location.href = '/myTasks/allMyLs')
                          }>
                          <div className={styles['sidebar-nav-title']}>
                            All My LS Requests
                          </div>
                        </li>
                      </>
                    )}
                    {props.userProfile.permissions.reportTracking.view_report &&
                      !rolesUnderCouncilMembers.includes(
                        props.userProfile.role
                      ) && (
                        <li
                          className={`${styles['sidebar-nav-item']} ${
                            styles['sidebar-subnav-item']
                          } ${
                            styles[
                              window.location.pathname.includes(
                                '/myTasks/myOpReqs'
                              )
                                ? 'active'
                                : ''
                            ]
                          }`}
                          onClick={() =>
                            (window.location.href = '/myTasks/myOpReqs')
                          }>
                          <div className={styles['sidebar-nav-title']}>
                            My Op Reqs
                          </div>
                        </li>
                      )}
                    {props.userProfile.permissions.wiki.view_page &&
                      !rolesUnderCouncilMembers.includes(
                        props.userProfile.role
                      ) && (
                        <li
                          className={`${styles['sidebar-nav-item']} ${
                            styles['sidebar-subnav-item']
                          } ${
                            styles[
                              window.location.pathname.includes(
                                '/myTasks/myCLEXReviews'
                              )
                                ? 'active'
                                : ''
                            ]
                          }`}
                          onClick={() =>
                            (window.location.href = '/myTasks/myCLEXReviews')
                          }>
                          <div className={styles['sidebar-nav-title']}>
                            My CLEX Reviews
                          </div>
                        </li>
                      )}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </li>
        {props.userProfile.permissions.lsRequest_api.view_lsrequest && (
          <li
            className={`${styles['sidebar-nav-item']} ${
              styles[
                window.location.pathname.includes('/lsRequestList')
                  ? 'active'
                  : ''
              ]
            }`}
            onClick={() => (window.location.href = '/lsRequestList')}>
            <div className={styles['sidebar-nav-icon']}>
              <LsRequestIcon />
            </div>
            <div className={styles['sidebar-nav-title']}>LS Requests</div>
          </li>
        )}
        {props.userProfile.permissions.reportTracking.view_report &&
          [...rolesUnderCouncilMembers.slice(0,6), ...attorneyRoles, ...legDivisionRoles, ...superAdminRoles].includes(
            props.userProfile.role
          ) && (
            <li
              className={`${styles['sidebar-nav-item']} ${
                styles[
                  window.location.pathname.includes('/opReqs') ? 'active' : ''
                ]
              }`}
              onClick={() => (window.location.href = '/opReqs')}>
              <div className={styles['sidebar-nav-icon']}>
                <ReportTrackingIcon />
              </div>
              <div className={styles['sidebar-nav-title']}>
                Operational Requirements
              </div>
            </li>
          )}
        {props.userProfile.permissions.wiki.view_page &&
          !rolesUnderCouncilMembers.includes(props.userProfile.role) && (
            <li
              className={`${styles['sidebar-nav-item']} ${
                styles[
                  window.location.pathname.includes('/CLEX') ? 'active' : ''
                ]
              }`}
              onClick={() => (window.location.href = '/CLEX')}>
              <div className={styles['sidebar-nav-icon']}>
                <ClexIcon />
              </div>
              <div className={styles['sidebar-nav-title']}>CLEX</div>
            </li>
          )}
        {props.userProfile.permissions.proclamationRequest
          .view_proclamationrequest && (
          <li
            className={`${styles['sidebar-nav-item']} ${
              styles[
                window.location.pathname.includes('/proclamation-requests')
                  ? 'active'
                  : ''
              ]
            }`}
            onClick={() => (window.location.href = '/proclamation-requests')}>
            <div className={styles['sidebar-nav-icon']}>
              <ProclamationRequestIcon />
            </div>
            <div className={styles['sidebar-nav-title']}>
              Proclamation Requests
            </div>
          </li>
        )}
        {props.userProfile.permissions.citationRequest.view_citationrequest && (
          <li
            className={`${styles['sidebar-nav-item']} ${
              styles[
                window.location.pathname.includes('/citation-requests')
                  ? 'active'
                  : ''
              ]
            }`}
            onClick={() => (window.location.href = '/citation-requests')}>
            <div className={styles['sidebar-nav-icon']}>
              <CitationRequestIcon />
            </div>
            <div className={styles['sidebar-nav-title']}>Citation Requests</div>
          </li>
        )}
        <li
          className={`${styles['sidebar-nav-item']} ${
            styles[window.location.pathname.includes('/staff') ? 'active' : '']
          }`}
          onClick={() => (window.location.href = '/staff')}>
          <div className={styles['sidebar-nav-icon']}>
            <StaffDirectoryIcon />
          </div>
          <div className={styles['sidebar-nav-title']}>Directory</div>
        </li>
        <li
          className={`${styles['sidebar-nav-item']}`}
          onClick={() =>
            window.open(
              'https://rnd.council.nyc.gov/shiny/DashboardDepot/',
              '_blank'
            )
          }>
          <div className={styles['sidebar-nav-icon']}>
            <DataTeamExplorerIcon />
          </div>
          <div className={styles['sidebar-nav-title']}>
            <div className='d-flex flex-column align-items-start'>
              <span className='badge text-bg-secondary me-2'>New</span>
              <span>
              Dashboard Depot <OutboundLinkIcon width='12' />
              </span>
            </div>
          </div>
        </li>
        {props.userProfile.desk_number && (
          <li
            className={`${styles['sidebar-nav-item']} ${
              styles[
                window.location.pathname.includes('/seating') ? 'active' : ''
              ]
            }`}
            onClick={seatingHandler}>
            <div className={styles['sidebar-nav-icon']}>
              <FloorPlanIcon />
            </div>
            <div className={styles['sidebar-nav-title']}>
              14<sup>th</sup> Floor Map
            </div>
          </li>
        )}
      </ul>
    </nav>
  );
}

const mapStateToProps = state => {
  return {
    userProfile: state.userReducer.userProfile,
  };
};

export default connect(mapStateToProps)(Sidebar);
